<template>
  <v-card elevation="0">
    <v-toolbar height="80" elevation="0">
      <div class="font-size-h3 text-primary">Chats</div>
      <v-spacer />
      <v-btn @click="openuserlist = true" rounded color="primary" elevation="0">
        Start New
      </v-btn>
    </v-toolbar>

    <perfect-scrollbar
      class="scroll"
      style="height: calc(100vh - 80px); position: relative"
    >
      <div
        style="
          height: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        v-if="loading"
      >
        <v-progress-circular indeterminate color="primary" size="30" />
      </div>
      <div
        style="
          height: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        v-else-if="rooms.length == 0"
      >
        Start A Conversation
      </div>
      <v-list v-else>
        <v-list-item-group v-model="roomselected">
          <v-list-item v-for="room in rooms" :key="room.id">
            <v-list-item-avatar color="#f2f7fd" size="40">
              <v-avatar
                v-if="room.remote != null && room.remote.avatar"
                color="#f2f7fd"
                size="40"
              >
                <v-img
                  :src="
                    $root.apiURL + 'assets/' + room.remote.avatar.private_hash
                  "
                ></v-img>
              </v-avatar>
              <v-avatar v-else color="#f2f7fd" size="40">
                <div
                  style="text-transform: uppercase; width: 100%"
                  class="primary--text font-size-h1"
                >
                  <span v-if="room.remote != null && room.remote.first_name">{{
                    room.remote.first_name.charAt(0)
                  }}</span>
                  <span v-else>D</span>
                </div>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="room.remote"
                  >{{ room.remote.first_name }}
                  {{ room.remote.last_name }}</span
                >
                <span v-else>{{ room.title }}</span></v-list-item-title
              >
              <v-list-item-subtitle v-if="room.chat">
                {{room.chat[room.chat.length-1].msg}}
                
            </v-list-item-subtitle>
            </v-list-item-content>
            
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </perfect-scrollbar>
    <v-dialog scrollable v-model="openuserlist" width="40vw">
      <v-card flat>
        <v-toolbar
          color="rgb(238, 240, 248)"
          style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
          height="64"
        >
          <v-btn icon dark color="primary" @click="openuserlist = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-primary"> Select User </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="px-0 mx-0" style="height: 80vh">
          <div
            style="
              height: 30vh;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            v-if="loadingusers"
          >
            <v-progress-circular indeterminate color="primary" size="30" />
          </div>
          <v-list v-else>
            <v-list-item-group>
              <v-list-item
                @click="startconvowith(user)"
                v-for="user in filteredUsers"
                :key="user.external_id"
              >
                <v-list-item-avatar color="#f2f7fd" size="40">
                  <v-avatar v-if="user.avatar" color="#f2f7fd" size="40">
                    <v-img
                      :src="$root.apiURL + 'assets/' + user.avatar.private_hash"
                    ></v-img>
                  </v-avatar>
                  <v-avatar v-else color="#f2f7fd" size="40">
                    <div
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text font-size-h1"
                    >
                      <span v-if="user.first_name">{{
                        user.first_name.charAt(0)
                      }}</span>
                      <span v-else>D</span>
                    </div>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><span v-if="user.role.id == 3">Dr</span>
                    {{ user.first_name }}
                    {{ user.last_name }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <span v-if="user.role.id == 3">{{
                      user.department.name
                    }}</span>
                    <span v-else>
                      {{ user.role.name }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      rooms: [],
      loading: true,
      openuserlist: false,
      loadingusers: true,
      users: [],
      roomselected:null,
    };
  },
  methods: {
    startconvowith(user) {
      console.log("start with", user);
      this.openuserlist = false;
      const toadd = this.$chat.generateRoomObj(
          this.$user.getCurrentUser().id,
          [this.$user.getCurrentUser(), user],
          this.$util.generateUID()
        );
        console.log("chatroom created is",toadd)
      this.rooms.push(
        toadd
      );
          this.$chat.setActiveRoom(toadd);
        this.roomselected = this.rooms.length -1
      //this.$chat.createRoom([this.$user.getCurrentUser(),user],this.$util.generateUID());
    },
    async getConvos() {
      await this.$chat
        .getUserChats(this.$user.getCurrentUser().id)
        .then((data) => {
          this.rooms = data;
            
          this.loading = false;
        });
    },
    newMsgHandle(data, index)
    {
        const parsed = JSON.parse(data);
        console.log("got this in background",parsed,index)
        if(parsed.msg)
        {
            this.rooms[index].chat.push(parsed.msg);
            this.$forceUpdate();
        }
        
    }
  },
  watch: {
      roomselected: function(update)
      {
          if(update != null)
          {
              if(this.$chat.getActiveRoom() == null || this.$chat.getActiveRoom().key != this.rooms[update].key)
                this.$chat.setActiveRoom(this.rooms[update]);
          }
      },
      rooms: function(newrooms)
      {
          console.log("rooms updated", newrooms)
          newrooms.forEach((room,index) => {
              this.$util.getEmitter().unsubscribe(room.key);
              this.$util.getEmitter().subscribe(room.key, (data) => {this.newMsgHandle(data, index)})
          })
      },
    openuserlist: function (updated) {
      if (updated) {
        this.$user.getElegiableChatUsers().then((data) => {
          console.log("users are", data);
          this.users = data;
         
          this.loadingusers = false;
        });
      }
    },
  },
  mounted() {
    this.getConvos();

    this.$util.EventBus.$on("setChatHistoryToRoom", (data)=> {
        console.log("chat history update", data)
        if(data.index >= 0)
            this.rooms[data.index].chat = data.chat
        else
        {
            const key = this.rooms.findIndex((r) => { return r.key == data.key})
            this.rooms[key].chat = data.chat
        }
        this.$forceUpdate();
    })
  },
  computed: {
    filteredUsers() {
      return this.users.filter((user) => {
        const notcurrent = this.$user.getCurrentUser().id !== user.id;
        let isinroom = false;
        this.rooms.forEach((r) => {
          if (
            r.users.find((u) => {
              return u.id == user.id;
            }) != undefined
          ) {
            isinroom = true;
            return;
          }
        });
        return notcurrent && !isinroom;
      });
    },
  },
};
</script>

<style></style>
